import React from "react";
import styled from "styled-components";
import "../App.css";

import Socials from "../components/socials";

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  overflow: hidden;

  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const List = styled.ul`
  list-style-type: none;
  padding: 20px 5px;
  margin: 0;
  text-align: center;
  font-family: Inconsolata, "monospace";
`;

const Title = styled.h1`
  text-transform: uppercase;
  margin: 0;
  font-size: 70px;
  font-family: "FuturaPT", "monospace";
  padding: 10px 0;
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const ListItem = styled.li`
  padding: 5px;
`;

const Text = styled.div`
  margin-bottom: 20px;
  font-size: 20px;
  width: 90%;
  font-family: Inconsolata, "monospace";
  text-align: center;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const LargeText = styled.div`
  margin-bottom: 20px;
  font-size: 30px;
  width: 90%;
  font-family: Inconsolata, "monospace";
  text-align: center;
  font-weight: bold;
  @media only screen and (min-width: 480px) {
  }
  @media only screen and (min-width: 768px) {
  }
  @media only screen and (min-width: 1024px) {
  }
  @media only screen and (min-width: 1600px) {
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: black;
  height: 2px;
  border: 0 none;
`;

var pageWidth = window.screen.availWidth;

const Hours = () => {
  return (
    <Container>
      {/* <Title>Opening Hours</Title> */}
      <Line />
      <LargeText>OPEN</LargeText>
      <Text>WEDNESDAY 4PM - 9:30PM</Text>
      <Text>THURSDAY 4PM - 9:30PM</Text>
      <Text>FRIDAY 4PM - 10PM</Text>
      <Text>SATURDAY 4PM - 10PM</Text>
      <Text><br/>KITCHEN HOURS: 4-8pm<br/></Text>
      <LargeText><br/><strong>CLOSED</strong></LargeText>
      <Text>SUN, MON, TUES</Text>
      <Text>* monthly events on Sunday, updates on social media</Text>
    </Container>
  );
};

export default Hours;
